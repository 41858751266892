import { DataProtectionContext } from '../../contexts/DataProtectionProvider'
import ConsentDialog from './ConsentDialog'
import { Snackbar } from '@mui/material'
import { Link } from 'gatsby'
import React, { useCallback,
useContext } from 'react'

const ConsentBanner: React.FC = () => {
  const {
    setConsent,
    setShowConsentBanner,
    setShowConsentDialog,
    showConsentBanner
  } = useContext(DataProtectionContext)

  const handleClose = useCallback(() => {
    setShowConsentBanner(false)
  }, [setShowConsentBanner])

  const handleLearnMore = useCallback(() => {
    setShowConsentDialog(true)
    handleClose()
  }, [handleClose, setShowConsentDialog])

  const handleAcceptAll = useCallback(() => {
    setConsent({
      analytics: true,
      functional: true,
      required: true
    })
    handleClose()
  }, [handleClose, setConsent])

  const handleAcceptRequiredOnly = useCallback(() => {
    setConsent({
      analytics: false,
      functional: false,
      required: true
    })
    handleClose()
  }, [handleClose, setConsent])

  return (
    <>
      <Snackbar
        ClickAwayListenerProps={{
          mouseEvent: false,
          touchEvent: false
        }}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        disableWindowBlurListener
        onClose={handleClose}
        open={showConsentBanner}
        sx={{
          '& .MuiSnackbarContent-action': {
            width: '100%'
          },
          '& .MuiSnackbarContent-message': {
            marginBottom: 1
          },
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'white',
            borderRadius: 2,
            color: '#000',
            padding: 2
          }
        }}>
        <div className="bg-canvas-contrast p-4 rounded-lg shadow-lg border-[0.5px] border-canvas-line flex flex-col md:flex-row gap-4">
          <div>
            <div className="font-bold mb-1">This website uses cookies 🍪</div>
            <p className="text-sm mb-0">
              Our website requires some cookies to function properly. In
              addition, other cookies may be used to analyze site usage, improve
              the user experience and for advertising. For more information,
              please review your{' '}
              <button
                className="underline"
                onClick={handleLearnMore}
                type="button">
                cookie preferences
              </button>{' '}
              options. By visiting our website, you agree to our processing of
              information as described in ICOSE's{' '}
              <Link to="/privacy-policy">privacy policy</Link>.
            </p>
          </div>
          <div className="w-full flex flex-col justify-evenly md:items-end gap-4">
            <button
              className="button highlighted"
              color="inherit"
              onClick={handleAcceptAll}
              type="button">
              Accept all
            </button>
            <button
              className="button"
              color="inherit"
              onClick={handleAcceptRequiredOnly}
              type="button">
              Required only
            </button>
          </div>
        </div>
      </Snackbar>
      <ConsentDialog />
    </>
  )
}

export default ConsentBanner
