module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KV77TJMR","respectDNT":true,"includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3755ae","options":{"minimum":0.4,"showSpinner":false,"trickle":false},"showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"optimize_id":"OPT_CONTAINER_ID"},"pluginConfig":{"exclude":[],"head":false,"respectDNT":true,"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"trackingIds":["G-TE8VN4MSYT"]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
